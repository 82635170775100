var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scroll-fixed-header',{attrs:{"fixed":_vm.fixed,"threshold":120},on:{"update:fixed":function($event){_vm.fixed=$event}}},[_c('header',{staticClass:"bg-white shadow"},[_c('div',{staticClass:"max-w-7xl mx-auto px-8 lg:divide-y lg:divide-gray-200 lg:px-8"},[_c('div',{staticClass:"relative flex justify-between"},[(_vm.fixed == false)?_c('div',{staticClass:"relative z-0 flex-1 py-5 flex items-center justify-center sm:inset-0"},[_c('div',{staticClass:"w-full sm:max-w-xs"},[_c('Logo')],1)]):_vm._e(),_c('div',{staticClass:"relative z-10 flex items-center lg:hidden"},[_c('button',{staticClass:"rounded-md p-2 inline-flex items-center justify-center text-mahanaim-blue-500 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500",attrs:{"type":"button","aria-controls":"mobile-menu","aria-expanded":"false"},on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Open menu")]),_c('svg',{staticClass:"block h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M4 6h16M4 12h16M4 18h16"}})]),_c('svg',{staticClass:"hidden h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])])]),_c('div',{staticClass:"hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center"})]),_c('nav',{staticClass:"hidden lg:py-3 lg:flex lg:space-x-8 lg:justify-center",attrs:{"aria-label":"Global"}},[_c('router-link',{staticClass:"rounded-md py-2 px-3 inline-flex items-center text-sm font-medium",class:_vm.$route.name == 'index' 
              ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium',attrs:{"to":{name: 'index'},"aria-current":"page"}},[_vm._v(" Home ")]),_c('router-link',{staticClass:"rounded-md py-2 px-3 inline-flex items-center text-sm font-medium",class:_vm.$route.name == 'greetings' 
              ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium',attrs:{"to":{name: 'greetings', params: { id: '1', slug: 'greetings' }}}},[_vm._v(" About Us ")]),_c('router-link',{staticClass:"rounded-md py-2 px-3 inline-flex items-center text-sm font-medium",class:['feeStructure', 'feeSummary'].includes(_vm.$route.name)
              ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium',attrs:{"to":{name: 'feeStructure', params: { id: '2', slug: 'fee-structure' }}}},[_vm._v(" Admissions ")]),_c('router-link',{staticClass:"rounded-md py-2 px-3 inline-flex items-center text-sm font-medium",class:['schools', 'courses', 'descr'].includes(_vm.$route.name)
              ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium',attrs:{"to":{name: 'schools', params: { id: '1', slug: 'schools' }}}},[_vm._v(" Academics ")]),_c('router-link',{staticClass:"rounded-md py-2 px-3 inline-flex items-center text-sm font-medium",class:['blog', 'blog-detail'].includes(_vm.$route.name)
              ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium',attrs:{"to":{name: 'blog', params: { id: '1', slug: 'blog' }}}},[_vm._v(" News ")]),_c('router-link',{staticClass:"rounded-md py-2 px-3 inline-flex items-center text-sm font-medium",class:_vm.$route.name == 'contact' 
              ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium',attrs:{"to":{name: 'contact'}}},[_vm._v(" Contact Us ")]),_c('router-link',{staticClass:"rounded-md py-2 px-3 inline-flex items-center text-sm font-medium",class:['career', 'ViewCareer'].includes(_vm.$route.name)
              ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium',attrs:{"to":{name: 'career'}}},[_vm._v(" Career ")]),_c('div',{staticClass:"md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0"},[_c('a',{staticClass:"whitespace-no-wrap text-base leading-6 font-semibold text-gray-600 hover:text-gray-900 transition ease-in-out duration-150",attrs:{"href":_vm.links.login,"target":"about_blank"}},[_vm._v(" Student Login ")]),_c('span',{staticClass:"inline-flex rounded-md shadow-sm"},[_c('a',{staticClass:"whitespace-no-wrap inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-mahanaim-blue-500 hover:bg-mahanaim-blue-600 focus:outline-none transition ease-in-out duration-150",attrs:{"href":_vm.links.register,"target":"about_blank"}},[_vm._v(" Register ")])])])],1)]),(_vm.toggle == true)?_c('nav',{staticClass:"lg:hidden border-t",attrs:{"aria-label":"Global","id":"mobile-menu"}},[_c('div',{staticClass:"px-8 pt-8 pb-4 space-y-1",on:{"click":function($event){_vm.toggle ==false}}},[_c('router-link',{staticClass:"block rounded-md py-2 px-3 text-base font-medium",class:_vm.$route.name == 'index'
          ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium',attrs:{"to":{name: 'index'},"aria-current":"page"}},[_vm._v("Home")]),_c('router-link',{staticClass:"block rounded-md py-2 px-3 text-base font-medium",class:_vm.$route.name == 'greetings'
          ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium',attrs:{"to":{name: 'greetings', params: { id: '1', slug: 'greetings' }}}},[_vm._v("About Us")]),_c('router-link',{staticClass:"block rounded-md py-2 px-3 text-base font-medium",class:['feeStructure', 'feeSummary'].includes(_vm.$route.name)
          ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium',attrs:{"to":{name: 'feeStructure', params: { id: '2', slug: 'fee-structure' }}}},[_vm._v("Admissions")]),_c('router-link',{staticClass:"block rounded-md py-2 px-3 text-base font-medium",class:['schools', 'courses', 'descr'].includes(_vm.$route.name)
          ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium',attrs:{"to":{name: 'schools', params: { id: '1', slug: 'schools' }}}},[_vm._v("Academics")]),_c('router-link',{staticClass:"block rounded-md py-2 px-3 text-base font-medium",class:['blog', 'blog-detail'].includes(_vm.$route.name)
          ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium',attrs:{"to":{name: 'blog', params: { id: '1', slug: 'blog' }}}},[_vm._v("News")]),_c('router-link',{staticClass:"block rounded-md py-2 px-3 text-base font-medium",class:_vm.$route.name == 'contact'
          ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium',attrs:{"to":{name: 'contact'}}},[_vm._v("Contact Us")]),_c('router-link',{staticClass:"block rounded-md py-2 px-3 text-base font-medium",class:['career', 'ViewCareer'].includes(_vm.$route.name)
          ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium',attrs:{"to":{name: 'career'}}},[_vm._v("Career")])],1),_c('div',{staticClass:"px-8 border-t pt-6 pb-8"},[_c('a',{staticClass:"w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-mahanaim-blue-500 hover:bg-mahanaim-blue-600",attrs:{"href":_vm.links.register,"target":"about_blank"}},[_vm._v(" Register ")]),_c('p',{staticClass:"mt-6 text-center text-base font-medium text-gray-500"},[_c('a',{staticClass:"text-mahanaim-blue-500 hover:text-mahanaim-blue-600",attrs:{"href":_vm.links.login,"target":"about_blank"}},[_vm._v(" Student Login ")])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }