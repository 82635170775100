<template>

  <div class="relative bg-white border-t-6 border-blue-700">
   
   <!-- Header -->
   <!-- <Header/> -->

    <!-- Pages will be rendered here -->
    <vue-page-transition name="fade-in-down">
      <router-view :key="$route.fullPath"></router-view>
    </vue-page-transition>

  

<!-- footer -->
<Footer/>

  </div>
</template>

<script>
// import Header from './components/header.vue'
import Footer from './components/footer.vue'
export default {

  data() {
    return {
      
    }
  },
  components:{
    Footer,
    // Header,
  },

}
</script>

<style>
a:-webkit-any-link{
  text-decoration:none;
}
p{
  text-align: justify
}
</style>