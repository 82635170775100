<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <!-- Header -->
    <Header />
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <HeaderImage />
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">Fee Structure Summary</h1>
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">
        <!---Content--->
        <div class="relative bg-white max-w-7xl mx-auto grid grid-cols-3 gap-2 lg:gap-8 lg:items-start">
            <div class="col-span-full md:col-span-2 pb-16 md:pt-16 order-last md:order-first">
                <!---Intoduction--->
                <h1>
                    <span class="mt-8 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        {{summary.description}}</span>
                </h1>

                <p class="mt-8 text-xl text-gray-500 leading-8">{{ summary.par1 }}</p>
                <p class="mt-8 text-xl text-gray-500 leading-8">{{ summary.par2 }}</p>
            </div>
            <!-- sidenav -->
            <sideNav />
        </div>
    </div>
</div>
</template>

<script>
// import gql from "graphql-tag";
import sideNav from './sideNav.vue'
// import Header from './header.vue'
import Header from '../components/header.vue'
import HeaderImage from './headerImage.vue'
export default {
    data() {
        return {
            fixed: false,
            admission: [],
            summary: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        sideNav,
        Header,
        HeaderImage
    },
    mounted() {
        window.scrollTo(0, 0);

        // this.axios.get(`https://cms.mahanaim.ac.ke/admissions/${this.$route.params.id}`).then(response => {
        //     this.admission = response.data
        // });

        this.axios.get(`https://cms.mahanaim.ac.ke/fees/${this.$route.params.id}`).then(response => {
            this.summary = response.data
        });

    }
}
</script>

<style>

</style>
