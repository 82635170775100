<template>
<div class="relative bg-white border-t-6 border-blue-700">
   <!-- Header -->
    <Header/>

    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <HeaderImage/>
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">

        <!---Content--->
        <div class="relative bg-white max-w-7xl mx-auto lg:grid lg:grid-cols-3 lg:gap-8 lg:items-start">
            <div class="relative px-4 sm:px-6 lg:px-8 col-span-2 py-16">
                <!---Intoduction--->
                <div class="mt-6 prose prose-blue prose-lg text-gray-500">

                    <!-- {{aboutAlumnus.name}} -->
                    <h2>{{aboutAlumnus.name}}</h2>
                    <figure>
                        <img class="w-full rounded-lg" :src="api_url + aboutAlumnus.image.url" alt="" width="1310" height="873">
                        <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                    </figure>

                    <p class="mt-8 text-xl text-gray-500 leading-8">{{ aboutAlumnus.par1 }}</p>
                    <p class="mt-8 text-xl text-gray-500 leading-8">{{ aboutAlumnus.par2 }}</p>
                    <p class="mt-8 text-xl text-gray-500 leading-8">{{ aboutAlumnus.par3 }}</p>
                    <p class="mt-8 text-xl text-gray-500 leading-8">{{ aboutAlumnus.par4 }}</p>
                    <p class="mt-8 text-xl text-gray-500 leading-8">{{ aboutAlumnus.par5 }}</p>
                    <p class="mt-8 text-xl text-gray-500 leading-8">{{ aboutAlumnus.par6 }}</p>

                </div>

            </div>
            <sideNav />
        </div>
    </div>
</div>
</template>

<script>
// import gql from "graphql-tag";
import sideNav from './sideNav.vue'
// import Header from './header.vue'
import Header from '../components/header.vue'
import HeaderImage from './headerImage.vue'
export default {
    data() {
        return {

            aboutAlumnus: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        sideNav,
        Header,
        HeaderImage
    },
    mounted() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/about-alumni/${this.$route.params.id}`).then(response => {
            this.aboutAlumnus = response.data
        });
    }
}
</script>

<style scoped>
.truncate {

    width: 150px;
    /* overflow: hidden; */
}
</style>
