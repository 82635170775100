<template>
<div v-if="image.academics" class="absolute inset-0">
    <img class="w-full h-full object-cover z-0" :src="api_url + image.academics.url" alt="">
    <div class="absolute inset-0 bg-blue-800 opacity-80 rounded-md" aria-hidden="true"></div>
</div>
</template>

<script>
export default {
    data() {
        return {
            image: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    mounted() {
        this.axios.get(`https://cms.mahanaim.ac.ke/header-images`).then(response => {
            this.image = response.data
        });
    },
}
</script>

<style>

</style>
