<template>
<div>
    <p>All they need is a healthy heart. I want to teach young people to break out of their molds, spend
        time with each other, and share their hearts. There are reasons why we take the time to bend our hearts and empty
        ourselves of our thoughts. If not done at a young age, it would be impossible to achieve success later in life.</p>

    <blockquote>
        <p>If young people could
            foresee the change they will undergo, they would not live according to the desires of their flesh, but cherish a greater
            hope in heaven.</p>
    </blockquote>

    <p>Even
        though we have shortcomings, we can receive the help of others to compensate our shortcomings through unity and
        fellowship. Tomorrow about this time, next year about this time, even ten years about this time, if young people could
        foresee the change they will undergo, they would not live according to the desires of their flesh, but cherish a greater
        hope in heaven. God has prepared an amazing future for us, and as young adults we need to learn about the true world of
        the heart, Mahanaim, which means God’s Camp, will raise them into great men and women with the capacity to lead the
        world. This change will come through Mahanaim Educational Institute(College).</p>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
