<template>
<div class="relative">
    <!-- <img class="h-10 w-auto sm:h-20 md:h-30" src="../assets/img/mclogo.svg" alt="Mahanaim College"> -->
    <img class="h-12 w-auto sm:h-20 md:h-30" src="../assets/img/mclogo.svg" alt="Mahanaim College">
</div>

</template>

<script>
export default {

}
</script>

<style>

</style>
