<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <!-- Header -->
    <Header />
    <div class="bg-white">
        <div class="max-w-screen-xl mx-auto px-8 pb-8">
            <div v-if="home.section1" class="relative mt-5 bg-mahanaim-blue-500 rounded-md md:rounded-lg overflow-hidden lg:grid lg:grid-cols-2 lg:gap-0">
                <div class="md:absolute z-0">
                    <img class="inset-0 w-full h-full object-cover object-left-top" :src="api_url + home.section1.img.url" alt="Banner">
                </div>
                <div class="z-10 p-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0">
                    <div class="lg:self-center">
                        <h2 class="text-5xl leading-12 font-bold text-white sm:text-6xl sm:leading-10 lg:mt-40">
                            <span class="block text-yellow-500">{{home.section1.head1}}</span>
                            <span class="block lg:my-6">{{home.section1.head2}}</span>
                        </h2>
                        <p class="mt-4 text-2xl leading-7 text-gray-100 mb-0 md:mb-5">{{home.section1.head3}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- TQQQ -->
    <div class="max-w-screen-xl mx-auto px-8 pb-8">
        <div class="bg-yellow-600 rounded-md">
            <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div class="flex items-center justify-between flex-wrap">
                    <div class="w-0 flex-1 flex items-center">
                        <span class="flex p-2 rounded-lg bg-yellow-800">
                            <!-- Heroicon name: outline/speakerphone -->
                            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                            </svg>
                        </span>
                        <p v-if="home.section1" class="ml-3 font-medium text-white truncate text-center">
                            <span class="md:hidden">
                                TVETA/PRIVATE/TVC/0221/2016 diploma & certificate.
                            </span>
                            <span class="hidden md:inline">
                                {{home.section1.head4}}
                            </span>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div v-if="home.section2" class="max-w-screen-xl mx-auto px-8 pb-8">
        <div class="relative bg-blue-700 rounded-t-md">
            <div class="h-56 bg-blue-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                <img class="w-full h-full object-cover" :src="api_url + home.section2.img1.url" alt="">
            </div>
            <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                <div class="md:ml-auto md:w-1/2 md:pl-10">
                    <h2 class="text-base font-semibold uppercase tracking-wider text-gray-300">
                        {{home.section2.head1}}
                    </h2>
                    <p class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                        {{home.section2.head2}}
                    </p>
                    <p class="mt-3 text-lg text-gray-300">
                        {{home.section2.par1}}
                    </p>
                    <div class="mt-8">
                        <div class="inline-flex rounded-md shadow">
                            <a v-bind:href="links.register" target="about_blank" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
                                Apply Now
                                <!-- Heroicon name: solid/external-link -->
                                <svg class="-mr-1 ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative bg-white rounded-b-md">
            <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                <div class="md:mr-auto md:w-1/2 md:pr-10">
                    <h2 class="text-base font-semibold uppercase tracking-wider text-gray-900">
                        {{home.section2.head3}}
                    </h2>
                    <p class="mt-2 text-blue-800 text-3xl font-extrabold tracking-tight sm:text-4xl">
                        {{home.section2.head4}}
                    </p>
                    <p class="mt-3 text-lg text-gray-900">
                        {{home.section2.par2}}
                    </p>
                    <div class="mt-8">
                        <div class="inline-flex rounded-md shadow">
                            <a v-bind:href="links.apply" target="about_blank" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-gray-50">
                                Apply Now
                                <!-- Heroicon name: solid/external-link -->
                                <svg class="-mr-1 ml-3 h-5 w-5 text-gray-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-56 bg-blue-600 sm:h-72 md:absolute md:right-0 md:top-0 md:h-full md:w-1/2">
                <img class="w-full h-full object-cover" :src="api_url + home.section2.img2.url" alt="">
            </div>
        </div>
    </div>

    <!-- Why Mahanaim -->
    <div class="max-w-screen-xl mx-auto  px-8 bg-white">
        <!-- Header -->
        <div v-if="home.section3" class="relative pb-32 bg-gray-800">
            <div class="absolute inset-0">
                <img class="w-full h-full object-cover" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100" alt="">
                <div class="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true"></div>
            </div>
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                <h1 class="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">{{home.section3.heading}}</h1>
                <p class="mt-6 max-w-3xl text-xl text-gray-300">{{home.section3.description}}</p>
            </div>
        </div>

        <!-- Overlapping cards -->
        <section class="-mt-32 max-w-7xl mx-auto relative z-10 pb-10 px-4 sm:px-6 lg:px-8" aria-labelledby="contact-heading">
            <h2 class="sr-only" id="contact-heading">Contact us</h2>
            <div v-if="home.section3" class="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
                <div class="flex flex-col bg-white rounded-2xl shadow-xl">
                    <div class="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                        <div class="absolute top-0 p-5 inline-block bg-mahanaim-blue-600 rounded-xl shadow-lg transform -translate-y-1/2">
                            <!-- Heroicon name: outline/phone -->
                            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                        </div>
                        <h3 class="text-xl font-medium text-gray-900">{{home.section3.item1.title}}</h3>
                        <p class="mt-4 text-base text-gray-500">{{home.section3.item1.par}}</p>
                    </div>
                    <div class="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                        <!-- <a href="#" class="text-base font-medium text-indigo-700 hover:text-indigo-600">{{home.section3.item1.link}}<span aria-hidden="true"> &rarr;</span></a> -->
                    </div>
                </div>

                <div class="flex flex-col bg-white rounded-2xl shadow-xl">
                    <div class="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                        <div class="absolute top-0 p-5 inline-block bg-mahanaim-blue-600 rounded-xl shadow-lg transform -translate-y-1/2">
                            <!-- Heroicon name: outline/support -->
                            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                        </div>
                        <h3 class="text-xl font-medium text-gray-900">{{home.section3.item2.title}}</h3>
                        <p class="mt-4 text-base text-gray-500">{{home.section3.item2.par}}</p>
                    </div>
                    <div class="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                        <!-- <a href="#" class="text-base font-medium text-indigo-700 hover:text-indigo-600">{{home.section3.item2.link}}<span aria-hidden="true"> &rarr;</span></a> -->
                    </div>
                </div>

                <div class="flex flex-col bg-white rounded-2xl shadow-xl">
                    <div class="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                        <div class="absolute top-0 p-5 inline-block bg-mahanaim-blue-600 rounded-xl shadow-lg transform -translate-y-1/2">
                            <!-- Heroicon name: outline/newspaper -->
                            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                            </svg>
                        </div>
                        <h3 class="text-xl font-medium text-gray-900">{{home.section3.item3.title}}</h3>
                        <p class="mt-4 text-base text-gray-500">{{home.section3.item3.par}}</p>
                    </div>
                    <div class="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                        <!-- <a href="#" class="text-base font-medium text-indigo-700 hover:text-indigo-600">{{home.section3.item3.link}}<span aria-hidden="true"> &rarr;</span></a> -->
                    </div>
                </div>
            </div>
        </section>
    </div>

</div>
</template>

<script>
// import Header from './header.vue'
import Header from '../components/header.vue'
export default {
    data() {
        return {
            fixed: false,
            home: [],
            links: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        Header,
    },
    mounted() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/home`).then(response => {
            this.home = response.data
        });

        this.axios.get(`https://cms.mahanaim.ac.ke/auth-links`).then(response => {
            this.links = response.data
        });
    },
}
</script>

<style>

</style>
