<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <!-- Header -->
    <Header/>
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <div class="absolute inset-0">
                <img class="w-full h-full object-cover z-0" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100" alt="">
                <div class="absolute inset-0 bg-blue-800 opacity-80 rounded-md" aria-hidden="true"></div>
            </div>
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">College Life</h1>
                <!-- <p class="mt-6 text-xl text-blue-100 max-w-3xl">Mattis amet hendrerit dolor, quisque lorem pharetra.
                    Pellentesque lacus nisi urna, arcu sociis eu. Orci vel lectus nisl eget eget ut consectetur. Sit justo
                    viverra non adipisicing elit distinctio.</p> -->
            </div>
        </div>
    </div>

    <!---Content--->

    <div class="relative py-16 bg-white overflow-hidden max-w-7xl mx-auto">
        <div class="relative px-4 sm:px-6 lg:px-8">
            <!---Intoduction--->
            <div class="text-lg max-w-prose">
                <h1>
                    <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Introducing</span>
                    <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">JavaScript
                        for Beginners</span>
                </h1>
                <p class="mt-8 text-xl text-gray-500 leading-8">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh
                    sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam
                    nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p>
            </div>
            <!---body--->
            <div class="mt-6 prose prose-blue prose-lg text-gray-500">
                <p>Faucibus commodo massa rhoncus, volutpat. <strong>Dignissim</strong> sed <strong>eget risus
                        enim</strong>. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio
                    penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat.
                    Dignissim sed eget risus enim. <a href="#">Mattis mauris semper</a> sed amet vitae sed turpis id.</p>
                <ul>
                    <li>Quis elit egestas venenatis mattis dignissim.</li>
                    <li>Cras cras lobortis vitae vivamus ultricies facilisis tempus.</li>
                    <li>Orci in sit morbi dignissim metus diam arcu pretium.</li>
                </ul>
                <p>Quis semper vulputate aliquam venenatis egestas sagittis quisque orci. Donec commodo sit viverra aliquam
                    porttitor ultrices gravida eu. Tincidunt leo, elementum mattis elementum ut nisl, justo, amet, mattis.
                    Nunc purus, diam commodo tincidunt turpis. Amet, duis sed elit interdum dignissim.</p>
                <h2>From beginner to expert in 30 days</h2>
                <p>Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh.
                    Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed
                    tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam
                    turpis ipsum eu a sed convallis diam.</p>
                <blockquote>
                    <p>Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur neque
                        tristique pellentesque. Blandit amet, sed aenean erat arcu morbi.</p>
                </blockquote>
                <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet
                    vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque
                    erat velit.</p>
                <figure>
                    <img class="w-full rounded-lg" src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3" alt="" width="1310" height="873">
                    <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption>
                </figure>
                <h2>Everything you need to get up and running</h2>
                <p>Purus morbi dignissim senectus mattis <a href="#">adipiscing</a>. Amet, massa quam varius orci dapibus
                    volutpat cras. In amet eu ridiculus leo sodales cursus tristique. Tincidunt sed tempus ut viverra
                    ridiculus non molestie. Gravida quis fringilla amet eget dui tempor dignissim. Facilisis auctor
                    venenatis varius nunc, congue erat ac. Cras fermentum convallis quam.</p>
                <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet
                    vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque
                    erat velit.</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import gql from "graphql-tag";
// import Header from './header.vue'
import Header from '../components/header.vue'

export default {
    name: "",
    data() {
        return {
            fixed: false
            //   abouts: [],
            //   academics: [],
            //   admissions: [],
            //   resources: [],
            //   api_url: process.env.VUE_APP_STRAPI_API_URL,
            //   routeParam: this.$route.params.id,
        };
    },
    components: {
        Header,
    },
    mounted() {
        window.scrollTo(0, 0);
    },
}
</script>
