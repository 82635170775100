<template>
<div>
    <Header />

    <!-- Header -->
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <div class="absolute inset-0">
                <img class="w-full h-full object-cover z-0" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100" alt="">
                <div class="absolute inset-0 bg-blue-800 opacity-80 rounded-md" aria-hidden="true"></div>
            </div>
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">{{career.heading}}</h1>
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">

        <!---Content--->
        <div class="relative bg-white max-w-7xl mx-auto lg:grid lg:grid-cols-3 lg:gap-8 lg:items-start">
            <div v-html="career.body" class="relative px-4 sm:px-6 lg:px-8 col-span-2 py-16">

               
            </div>
            <!-- sidenav -->
            <sideNav />
        </div>
    </div>

</div>
</template>

<script>

import sideNav from './sideNav.vue'
// import Header from './header.vue'
import Header from '../components/header.vue'
export default {
    data() {
        return {
            fixed: false,
            career: {},
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        sideNav,
        Header,
    },
    mounted() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/careers/${this.$route.params.id}`).then(response => {
            this.career = response.data
        });
    }
}
</script>
