import Vue from "vue";
import App from "./App.vue";

import VueApollo from "vue-apollo";
import apolloClient from "./vue-apollo";

import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

import ScrollFixedHeader from 'vuejs-scroll-fixed-header';
Vue.use(ScrollFixedHeader);

import VueRouter from "vue-router";
Vue.use(VueRouter);

import VueAxios from 'vue-axios';
import axios from 'axios';
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

import './assets/build/tailwind.css'
import './assets/tailwind.css'

Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});


const router = new VueRouter({
  mode: "history",
  routes: [

    {
      path: "/",
      name: 'index',
      components: require("./home/home.vue")
    },

    {
      path: "/about/:id/:slug/",
      // path: "/about/greetings/",
      name: "greetings",
      components: require("./about/about.vue")
    },
    {
      path: "/chancellor",
      name: "test",
      components: require("./about/chancellor.vue")
    },
    {
      path: "/about/alumni/testimonials/:id/",
      // path: "/about/greetings/",
      name: "alumni",
      components: require("./about/alumni.vue")
    },
    {
      // path: "/about/greetings/:id/:slug/",
      path: "/admissions/:id/:slug/",
      name: "feeStructure",
      components: require("./admissions/admissions.vue")
    },
    {
      // path: "/about/greetings/:id/:slug/",
      path: "/admissions/fee-structure/summary/:id/",
      name: "feeSummary",
      components: require("./admissions/feeSummary.vue")
    },
    {
      // path: "/about/greetings/:id/:slug/",
      path: "/academics/:id/:slug/",
      name: "schools",
      components: require("./academics/academics.vue")
    },
    {
      // path: "/about/greetings/:id/:slug/",
      path: "/academics/schools/courses/:id/",
      name: "courses",
      components: require("./academics/courses.vue")
    },

    {
      // path: "/about/greetings/:id/:slug/",
      path: "/academics/schools/description/:id/",
      name: "descr",
      components: require("./academics/schDescr.vue")
    },
     
    {
      // path: "/about/greetings/:id/:slug/",
      path: "/resources/:id/:slug/",
      name: "blog",
      components: require("./resources/resources.vue")
    },
    {
      path: "/resources/blog/blog-detail/:id/",
      // path: "/about/greetings/",
      name: "blog-detail",
      components: require("./resources/blog.vue")
    },

    {
      path: "/college-life",
      name: 'college',
      components: require("./college/collegeLife.vue")
    },
    {
      path: "/contact-us",
      name: 'contact',
      components: require("./contact/contactUs.vue")
    },

    {
      path: "/career",
      name: "career",
      components: require("./career/career.vue")
    },
    {
      path: "/career/:id",
      name: "ViewCareer",
      components: require("./career/viewCareer.vue")
    },


  ]
});

new Vue({
  apolloProvider,
  router,
  render: h => h(App)
}).$mount("#app");
