<template>
<!-- <div class="relative bg-white rounded-bl-2xl border-2 col-span-1 -mt-20 mr-5">
    <div class="px-8 pt-7 pb-3 border-b"> -->
<div class="bg-white overflow-hidden rounded-bl-2xl border-2 col-span-3 md:col-span-1 md:-mt-20 md:mr-5 mb-10 md:mb-20 order-first md:order-last">
    <div class="px-8 pt-7 pb-3 border-b">
        <h1 class="text-3xl text-gray-900 font-extrabold">Academics</h1>
    </div>
    <ul class="divide-y divide-gray-200">

        <li v-for="academic in academics" :key="academic.id">
            <router-link :to="{
                  name: 'schools',
                  params: { id: academic.id, slug: academic.slug },
                }" class="px-8 py-4 flex justify-between items-center">{{academic.title}}
                <router-link :to="{
                  name: 'schools',
                  params: { id: academic.id, slug: academic.slug },
                }" class="icon"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 icon text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z" clip-rule="evenodd" />
                    </svg></router-link>
            </router-link>
        </li>

    </ul>
</div>
</template>

<script>
// import gql from "graphql-tag";

export default {
    name: "",
    data() {
        return {
            academics: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        };
    },
    mounted() {
        this.axios.get(`https://cms.mahanaim.ac.ke/academics`).then(response => {
            this.academics = response.data
        });
    }
};
</script>

<style scoped>
a.router-link-exact-active {

    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));

    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

a.router-link-exact-active {
    font-size: 1rem;
    line-height: 1.5rem;

}

a.icon {
    visibility: hidden;
}

a.router-link-exact-active.icon {
    visibility: visible;
}

a.router-link-exact-active {
    font-weight: 700;
}
</style>
