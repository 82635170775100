<template>
<div>
    <Header />

    <!-- Header -->
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <div class="absolute inset-0">
                <img class="w-full h-full object-cover z-0" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100" alt="">
                <div class="absolute inset-0 bg-blue-800 opacity-80 rounded-md" aria-hidden="true"></div>
            </div>
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">{{school.name}}</h1>
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">

        <!---Content--->
        <div class="relative bg-white max-w-7xl mx-auto lg:grid lg:grid-cols-3 lg:gap-8 lg:items-start">
            <div class="relative px-4 sm:px-6 lg:px-8 col-span-2 py-16">

                <div class="mt-6 prose prose-blue prose-lg text-gray-500">
                    <div>
                        <h2>{{ school.name }}</h2>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ school.par1 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ school.par2 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ school.par3 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ school.par4 }}</p>
                        
                      <div v-if="school.id == 1">
                           <p class="mt-8 text-xl text-gray-500 leading-8">The school has two departments:</p>
                        <li>Tourism department</li>
                        <li>Hospitality department</li>
                        <h3>1. Tourism department</h3>
                      </div>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ school.par5 }}</p>
                        <h3 v-if="school.id == 1">2.Hospitality department</h3>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ school.par6 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ school.par7 }}</p>
                    </div>
                </div>
            </div>
            <!-- sidenav -->
            <sideNav />
        </div>
    </div>

</div>
</template>

<script>
// import gql from "graphql-tag";
import sideNav from './sideNav.vue'
// import Header from './header.vue'
import Header from '../components/header.vue'
export default {
    data() {
        return {
            fixed: false,
            academic: [],
            school: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        sideNav,
        Header,
    },
    mounted() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/schools/${this.$route.params.id}`).then(response => {
            this.school = response.data
        });
    }
}
</script>
