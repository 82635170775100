<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <Header />
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-mahanaim-blue-500 rounded-t-md md:rounded-md">
            <HeaderImage />
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">{{academic.title}}</h1>
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">

        <!---Content--->
        <div class="relative bg-white max-w-7xl mx-auto grid grid-cols-3 gap-2 lg:gap-8 lg:items-start">
            <div class="col-span-full md:col-span-2 pb-16 md:pt-16 order-last md:order-first">
                 <div v-if="academic.id == '1'" class="">

                            <div class="text-lg max-w-prose">
                                <h1>
                                    <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{academic.heading}}</span>
                                    <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        {{academic.subheading}}</span>
                                </h1>
                            </div>
                            <div class="bg-white border-2 border-gray-200 overflow-hidden sm:rounded-md mt-9">
                                <ul role="list" class="divide-y divide-gray-200">
                                    <li v-for="school in schools" :key="school.id">
                                        <a class="block hover:bg-gray-50">
                                            <div class="px-4 py-4 sm:px-6">
                                                <div class="block md:flex items-center justify-between">
                                                    <p class="text-md font-medium text-gray-900 truncate">
                                                        {{school.name}}
                                                    </p>
                                                    <div class="mt-3 md:mt-0 md:ml-2 flex-shrink-0 flex">
                                                        <router-link :to="{name:'descr', params:{id: school.id}}" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mahanaim-blue-500">
                                                            Summary
                                                        </router-link>
                                                        <router-link :to="{name:'courses', params:{id: school.id}}" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-mahanaim-blue-500 hover:bg-mahanaim-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mahanaim-blue-500">
                                                            Courses
                                                        </router-link>
                                                    </div>
                                                </div>

                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <!-- Exam -->
                        <div v-if="academic.id == '2'" class="">
                            <div class="text-lg max-w-prose">
                                <h1>
                                    <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{academic.heading}}</span>
                                    <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        {{academic.subheading}}</span>
                                </h1>
                            </div>
                            <div class="bg-white border-2 border-gray-200 overflow-hidden sm:rounded-md mt-9">
                                <ul role="list" class="divide-y divide-gray-200">
                                    <li>
                                        <a class="block hover:bg-gray-50">
                                            <div class="px-4 py-4 sm:px-6">
                                                <div class="block md:flex items-center justify-between">
                                                    <p class="text-md font-medium text-gray-900 truncate">
                                                        {{academic.schedule1.name}}
                                                    </p>
                                                    <div class="mt-3 md:mt-0 md:ml-2 flex-shrink-0 flex">
                                                        <!-- <a href="admissions-feestructure-details.html" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mahanaim-blue-500">
                                                            Summary
                                                        </a> -->
                                                        <a v-bind:href="api_url + academic.schedule1.doc.url" download target="_blank" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-mahanaim-blue-500 hover:bg-mahanaim-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mahanaim-blue-500">
                                                            Download
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <!-- Examinations -->

                        <div v-if="academic.id == '3' " class="">
                            <div class="text-center">
                                <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                                    Examination
                                </h2>
                            </div>
                            <!-- <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                                <div v-for="school in schools" :key="school.id" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                    <div class="flex-shrink-0">
                                    </div>
                                    <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                                        <div class="flex-1">

                                            <h5 class="block mt-2">
                                                <p class="text-xl font-semibold text-gray-900">
                                                    {{school.name}}
                                                </p>
                                                <p class="truncate mt-3 text-base text-gray-500">
                                                    {{school.description}}
                                                </p><b>Courses</b>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>

                        <!-- Course Outline -->

                        <div v-if="academic.id == '4' " class="">
                            <div class="text-center">
                                <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                                    Course Outline
                                </h2>
                            </div>
                            <!-- <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                                <div v-for="school in schools" :key="school.id" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                    <div class="flex-shrink-0">
                                    </div>
                                    <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                                        <div class="flex-1">

                                            <h5 class="block mt-2">
                                                <p class="text-xl font-semibold text-gray-900">
                                                    {{school.name}}
                                                </p>
                                                <p class="truncate mt-3 text-base text-gray-500">
                                                    {{school.description}}
                                                </p><b>Courses</b>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>

            </div>
            <sideNav />
        </div>
    </div>
</div>
</template>

<script>
// import gql from "graphql-tag";
import sideNav from './sideNav.vue'
// import Header from './header.vue'
// import Header from '../components/header.vue'
import HeaderImage from './headerImage.vue'
import Header from '../components/header.vue'
export default {
    data() {
        return {
            fixed: false,
            academic: [],
            schools: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        sideNav,
        Header,
        HeaderImage
    },
    created() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/academics/${this.$route.params.id}`).then(response => {
            this.academic = response.data
        });

        this.axios.get(`https://cms.mahanaim.ac.ke/schools`).then(response => {
            this.schools = response.data
        });

        this.axios.get(`https://cms.mahanaim.ac.ke/about-alumni`).then(response => {
            this.aboutAlumni = response.data
        });
    }
}
</script>
