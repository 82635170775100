<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <!-- Header -->
    <Header />
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <headerImage />
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">{{contact.title}}</h1>
            </div>
        </div>
    </div>

    <!---Content--->
    <div class="max-w-screen-xl mx-auto px-8">

        <div class="relative bg-white">
            <div class="absolute inset-0">
                <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
            </div>
            <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                <div class="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:py-24 xl:px-16">
                    <div class="max-w-lg mx-auto">
                        <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                            {{contact.heading}}
                        </h2>
                        <p class="mt-3 text-lg leading-6 text-gray-500">
                           {{contact.par1}}
                        </p>
                        <dl class="mt-8 text-base text-gray-500">
                            <div>
                                <!-- <dt class="sr-only">Postal address</dt> -->
                                <dd>
                                    <p>{{contact.par2}}</p>
                                </dd>
                            </div>
                            <div class="mt-6">
                                <dt class="sr-only">Phone number</dt>
                                <dd class="flex">
                                    <!-- Heroicon name: outline/phone -->
                                    <svg class="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                    </svg>
                                    <span class="ml-3">
                                        {{contact.phone}}
                                    </span>
                                </dd>
                            </div>
                            <div class="mt-3">
                                <dt class="sr-only">Email</dt>
                                <a href="mailto:info@mei.ac.ke" class="flex">
                                    <!-- Heroicon name: outline/mail -->
                                    <svg class="flex-shrink-0 h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                    <span class="ml-3">
                                       {{contact.email}}
                                    </span>
                                </a>
                            </div>
                        </dl>
                    </div>
                </div>
                <div class="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                    <div class="max-w-lg mx-auto lg:max-w-none">
                        <form action="#" method="POST" class="grid grid-cols-1 gap-y-6">
                            <div>
                                <label for="full_name" class="sr-only">Full name</label>
                                <input type="text" name="full_name" id="full_name" autocomplete="name" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" placeholder="Full name">
                            </div>
                            <div>
                                <label for="email" class="sr-only">Email</label>
                                <input id="email" name="email" type="email" autocomplete="email" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" placeholder="Email">
                            </div>
                            <div>
                                <label for="phone" class="sr-only">Phone</label>
                                <input type="text" name="phone" id="phone" autocomplete="tel" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" placeholder="Phone">
                            </div>
                            <div>
                                <label for="message" class="sr-only">Message</label>
                                <textarea id="message" name="message" rows="4" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md" placeholder="Message"></textarea>
                            </div>
                            <div>
                                <button type="submit" class="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
// import Header from './header.vue'
import Header from '../components/header.vue'
import HeaderImage from './headerImage.vue'
export default {
    data() {
        return {
            fixed: false,
            image: {},
            contact:[],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        Header,
        HeaderImage
    },
    created() {
        window.scrollTo(0, 0);
        this.axios.get(`https://cms.mahanaim.ac.ke/header-images`).then(response => {
            this.image = response.data
        });

         window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/contact`).then(response => {
            this.contact = response.data
        });
    },
}
</script>

<style>

</style>
