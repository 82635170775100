<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <!-- Header -->
    <Header />
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <div class="absolute inset-0">
                <img class="w-full h-full object-cover z-0" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100" alt="">
                <div class="absolute inset-0 bg-blue-800 opacity-80 rounded-md" aria-hidden="true"></div>
            </div>
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">{{resource.title}}</h1>
                <!-- <p class="mt-6 text-xl text-blue-100 max-w-3xl">Mattis amet hendrerit dolor, quisque lorem pharetra.
                    Pellentesque lacus nisi urna, arcu sociis eu. Orci vel lectus nisl eget eget ut consectetur. Sit justo
                    viverra non adipisicing elit distinctio.</p> -->
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">

        <!---Content--->
        <div class="relative bg-white max-w-7xl mx-auto">

            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="relative pt-8 pb-20">
                <div class="absolute inset-0">
                    <div class="bg-white h-1/3 sm:h-2/3"></div>
                </div>
                <div class="relative max-w-7xl mx-auto">

                    <div v-if="resource.id == '1' " class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        <div v-for="blog in blogs" :key="blog.id" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div class="flex-shrink-0">
                                <img class="h-48 w-full object-cover" :src="api_url + blog.image.url" alt="">
                            </div>
                            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                                <div class="flex-1">
                                    <p class="text-sm font-medium text-blue-600">
                                        <router-link :to="{
                                                name: 'blog-detail',
                                                params: { id: blog.id },
                                                }" class="hover:underline">
                                            {{blog.title}}
                                        </router-link>
                                    </p>
                                    <router-link :to="{
                                                name: 'blog-detail',
                                                params: { id: blog.id },
                                                }" class="block mt-2">
                                        <p class="text-xl font-semibold text-gray-900">
                                            {{blog.subtitle}}
                                        </p>
                                        <p class="mt-3 text-base text-gray-500">
                                            {{blog.content.par1}}
                                        </p>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div v-if="resource.id == '2' " class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        <div v-for="gallary in galleries" :key="gallary.id" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                            <div class="flex-shrink-0">
                                <img class="h-48 w-full object-cover" :src="api_url + gallary.image.url" alt="">
                            </div>
                            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                                <div class="flex-1">
                                    <p class="text-sm font-medium text-blue-600">
                                        <a class="">
                                            {{gallary.title}}
                                        </a>
                                    </p>
                                    <!-- <a href="#" class="block mt-2">
                                        <p class="text-xl font-semibold text-gray-900">
                                            jnkj
                                        </p>
                                        <p class="mt-3 text-base text-gray-500">
                                           kmlijn
                                        </p>
                                    </a> -->
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
// import gql from "graphql-tag";
// import sideNav from './sideNav.vue'
// import Header from './header.vue'
import Header from '../components/header.vue'
export default {
    data() {
        return {
            fixed: false,
            resource: [],
            galleries: [],
            blogs: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        // sideNav,
        Header,
    },
    mounted() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/resources/${this.$route.params.id}`).then(response => {
            this.resource = response.data
        });

        this.axios.get(`https://cms.mahanaim.ac.ke/blogs`).then(response => {
            this.blogs = response.data
        });

        this.axios.get(`https://cms.mahanaim.ac.ke/galleries`).then(response => {
            this.galleries = response.data
        });
    }
}
</script>

<style>

</style>
