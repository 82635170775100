<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <!-- Header -->
    <Header />

    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <HeaderImage />
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 v-if="about.title" class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">{{about.title}}</h1>
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">
        <div class="relative bg-white max-w-7xl mx-auto grid grid-cols-3 gap-2 lg:gap-8 lg:items-start">
            <div class="col-span-full md:col-span-2 pb-16 md:pt-16 order-last md:order-first">
                <!---Intoduction--->
                <!-- <div class="mt-6 prose prose-blue prose-lg text-gray-500"> -->
                <div class="text-lg max-w-prose">
                    <div v-if="about.id == '1'">
                        <div class="text-lg max-w-prose">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{about.heading}}</span>
                                <!-- <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    {{academic.subheading}}</span> -->
                            </h1>
                        </div>
                        <h1>
                            <!-- <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Greetings</span> -->
                            <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{{about.chancellor.title}}</span>
                        </h1>
                        <!-- GREETINGS -->

                        <!-- chancellor's message -->
                        <p class="mt-8 text-xl text-gray-500 leading-8 text-justify">{{ about.chancellor.par1 }}</p><br>
                        <figure>
                            <img v-if="about.chancellor.image.url" class="w-full rounded-lg" :src="api_url + about.chancellor.image.url" alt="" width="1310" height="873">
                            <figcaption>{{about.chancellor.name}}</figcaption>
                        </figure>
                        <p class="mt-8 text-xl text-gray-500 leading-8 text-justify">{{ about.chancellor.par2 }}</p>
                        <blockquote class="quote">
                            <p>{{ about.chancellor.par3 }}</p>
                        </blockquote>
                        <p class="mt-8 text-xl text-gray-500 leading-8text-justify">{{ about.chancellor.par4 }}</p><br>

                    </div>

                    <!-- Chairmans Message -->
                    <div v-if="about.id == '5'">
                        <div class="text-lg max-w-prose">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{about.heading}}</span>
                                <!-- <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    {{academic.subheading}}</span> -->
                            </h1>
                        </div>

                        <h1>
                            <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{{about.principal.title}}</span>
                        </h1>

                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.principal.par1 }}</p><br>
                        <figure>
                            <img v-if="about.principal" :src="api_url + about.principal.image.url" alt="" width="500">
                            <figcaption>{{about.principal.name}}</figcaption>
                        </figure>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.principal.par2 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.principal.par3 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.principal.par4 }}</p>
                    </div>

                    <!-- MISSION -->

                    <div v-if="about.id == '6'">
                        <div class="text-lg max-w-prose">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{about.heading}}</span>
                                <!-- <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    {{academic.subheading}}</span> -->
                            </h1>
                        </div>

                        <h1>
                            <span class="mt-8 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                {{about.mission.title}}</span>
                        </h1>

                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.mission.par1 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.mission.par2 }}</p>

                        <!-- vision -->
                        <h1>
                            <span class="mt-8 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                {{about.vision.title}}</span>
                        </h1>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.vision.par1 }}</p>

                        <!-- philosophy -->
                        <h1>
                            <span class="mt-8 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                {{about.phylosophy.title}}</span>
                        </h1>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.phylosophy.par1 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.phylosophy.par2 }}</p>

                        <br><br>
                        <span v-if="about.slug == 'about-mission'" class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Our Motto</span>

                        <!-- challenge -->
                        <h1>
                            <span class="mt-8 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                {{about.challenge.title}}</span>
                        </h1>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.challenge.par1 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.challenge.par2 }}</p>

                        <!-- change -->
                        <h1>
                            <span class="mt-8 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                {{about.change.title}}</span>
                        </h1>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.change.par1 }}</p>

                        <!-- cohesion -->
                        <h1>
                            <span class="mt-8 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                {{about.cohesion.title}}</span>
                        </h1>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.cohesion.par1 }}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ about.cohesion.par2 }}</p>

                    </div>

                </div>

                <!-- Staff -->

                <div v-if="about.id == '7'" class="space-y-12">
                    <div class="text-lg max-w-prose">
                        <h1>
                            <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{about.heading}}</span>
                            <!-- <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    {{academic.subheading}}</span> -->
                        </h1>
                    </div>
                    <ul class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
                        <li v-for="aboutStaff in aboutStaffs" :key="aboutStaff.id">
                            <div class="space-y-4">
                                <div class="aspect-w-3 aspect-h-2">
                                    <img class="object-cover shadow-lg rounded-lg h-48 w-full object-cover" :src="api_url + aboutStaff.image.url" alt="">
                                </div>

                                <div class="space-y-2">
                                    <div class="text-lg leading-6 font-medium space-y-1">
                                        <h3>{{aboutStaff.name}}</h3>
                                        <p class="text-blue-600">{{aboutStaff.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- <ul class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
                        <li v-for="aboutStaff in aboutStaffs" :key="aboutStaff.id">
                            <div class="space-y-4">
                                <div class="aspect-w-3 aspect-h-2">
                                    <img class="object-cover shadow-lg rounded-lg" :src="api_url + aboutStaff.image.url" alt="">
                                </div>

                                <div class="space-y-2">
                                    <div class="text-lg leading-6 font-medium space-y-1">
                                        <h3>{{aboutStaff.name}}</h3>
                                        <p class="text-blue-600">{{aboutStaff.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul> -->
                </div>

                <div>
                    <div v-if="about.id == '8'" class="">
                        <div class="text-center">
                            <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                                Our Alumni
                            </h2>
                            <!-- <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed.
                                </p> -->
                        </div>
                        <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                            <div v-for="aboutAlumnus in aboutAlumni" :key="aboutAlumnus.id" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div class="flex-shrink-0">
                                    <img class="h-48 w-full object-cover" :src="api_url + aboutAlumnus.image.url" alt="">
                                </div>
                                <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div class="flex-1">

                                        <router-link :to="{
                                                name: 'alumni',
                                                params: { id: aboutAlumnus.id },
                                                }" class="block mt-2">
                                            <p class="text-xl font-semibold text-gray-900">
                                                {{aboutAlumnus.name}}
                                            </p>
                                            <p class="truncate mt-3 text-base text-gray-500">
                                                {{aboutAlumnus.par1}}
                                            </p><b>Read more</b>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <sideNav />
        </div>
    </div>
</div>
</template>

<script>
// import gql from "graphql-tag";
import sideNav from './sideNav.vue'
// import Header from './header.vue'
import Header from '../components/header.vue'
import HeaderImage from './headerImage.vue'
export default {
    data() {
        return {
            fixed: false,
            about: [],
            aboutStaffs: [],
            aboutAlumni: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        sideNav,
        Header,
        HeaderImage
    },
    mounted() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/abouts/${this.$route.params.id}`).then(response => {
            this.about = response.data
        });

        this.axios.get(`https://cms.mahanaim.ac.ke/about-staffs`).then(response => {
            this.aboutStaffs = response.data
        });

        this.axios.get(`https://cms.mahanaim.ac.ke/about-alumni`).then(response => {
            this.aboutAlumni = response.data
        });

    }
}
</script>

<style scoped>
.quote{
        font-weight: 500;
    font-style: italic;
    color: #111827;
    border-left-width: .25rem;
    border-left-color: #e5e7eb;
    quotes: "\201C""\201D""\2018""\2019";
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
}
</style>
