<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <!-- Header -->
    <Header />
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <div class="absolute inset-0">
                <img class="w-full h-full object-cover z-0" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100" alt="">
                <div class="absolute inset-0 bg-blue-800 opacity-80 rounded-md" aria-hidden="true"></div>
            </div>
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">Career</h1>
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">
        <!-- Content -->

        <div class="relative bg-white max-w-7xl mx-auto grid grid-cols-3 gap-2 lg:gap-8 lg:items-start">
            <div class="col-span-full md:col-span-2 pb-16 md:pt-16 order-last md:order-first">
                 <div class="">

                            <div class="text-lg max-w-prose">
                                <h1>
                                    <!-- <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">heading</span> -->
                                    <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        Career</span>
                                </h1>
                            </div>
                            <div class="bg-white border-2 border-gray-200 overflow-hidden sm:rounded-md mt-9">
                                <ul role="list" class="divide-y divide-gray-200">
                                    <li v-for="career in careers" :key="career.id">
                                        <a class="block hover:bg-gray-50">
                                            <div class="px-4 py-4 sm:px-6">
                                                <div class="block md:flex items-center justify-between">
                                                    <p class="text-md font-medium text-gray-900 truncate">
                                                        {{career.heading}}
                                                    </p>
                                                    <div class="mt-3 md:mt-0 md:ml-2 flex-shrink-0 flex">
                                                       
                                                        <router-link :to="{name: 'ViewCareer', params: {id: career.id}}" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-mahanaim-blue-500 hover:bg-mahanaim-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mahanaim-blue-500">
                                                            View Details
                                                        </router-link>
                                                    </div>
                                                </div>

                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                   
            </div>
            <sideNav />
        </div>

    </div>
</div>
</template>

<script>
// import gql from "graphql-tag";
import sideNav from './sideNav.vue'
// import Header from './header.vue'
import Header from '../components/header.vue'
export default {
    data() {
        return {
            fixed: false,
            careers: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        sideNav,
        Header,
    },
    mounted() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/careers`).then(response => {
            this.careers = response.data
        });

    }
}
</script>

<style>

</style>
