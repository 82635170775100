<template>
<div>
    <!-- Header -->
    <Header />
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <HeaderImage />
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">{{school.name}}</h1>
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">
        <div class="relative bg-white max-w-7xl mx-auto">
            <div class="relative pt-8 pb-20">
                <div class="absolute inset-0">
                    <div class="bg-white h-1/3 sm:h-2/3"></div>
                </div>
                <div class="relative max-w-7xl mx-auto">

                    <!-- School Of Tourism & Hospitality -->
                    <div v-if="school.id == 1">

                        <div>
                            <div class="mb-10">
                                <h1>
                                    <!-- <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span> -->
                                    <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        Tourism Short Courses</span>
                                </h1>
                            </div>
                            <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                                <section v-for="tdata in tsc" :key="tdata.id">
                                    <table class="w-full">
                                        <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                            {{tdata.name}}

                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </caption>

                                        <tbody class="divide-y divide-gray-200">
                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.modules}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.duration}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.grade}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.body}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </section>

                            </div>

                            <!-- lg+ -->
                            <div class="hidden lg:block">
                                <table class="w-full h-px table-fixed">

                                    <thead>
                                        <tr>
                                            <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                                <span>Course Name</span>
                                            </th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                            <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                        </tr>
                                    </thead>
                                    <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                        <tr v-for="tdata in tsc" :key="tdata.id">
                                            <td class="py-5 pr-6 w-6/12">
                                                <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                                <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {{tdata.mode}}
                                                    </span>
                                                </div>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                            </td>

                                            <td class="py-5 pl-6">
                                                <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                            </td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <br>

                        <div>
                            <div class="mb-10">
                                <h1>
                                    <!-- <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span> -->
                                    <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        Tourism Long Courses</span>
                                </h1>
                            </div>
                            <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                                <section v-for="tdata in tlc" :key="tdata.id">
                                    <table class="w-full">
                                        <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                            {{tdata.name}}

                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </caption>

                                        <tbody class="divide-y divide-gray-200">
                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.modules}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.duration}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.grade}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.body}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </section>

                            </div>

                            <!-- lg+ -->
                            <div class="hidden lg:block">
                                <table class="w-full h-px table-fixed">

                                    <thead>
                                        <tr>
                                            <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                                <span>Course Name</span>
                                            </th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                            <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                        </tr>
                                    </thead>
                                    <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                        <tr v-for="tdata in tlc" :key="tdata.id">
                                            <td class="py-5 pr-6 w-6/12">
                                                <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                                <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {{tdata.mode}}
                                                    </span>
                                                </div>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                            </td>

                                            <td class="py-5 pl-6">
                                                <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                            </td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <br>

                        <!-- Hospitality Short Courses -->
                        <div>
                            <div class="mb-10">
                                <h1>
                                    <!-- <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span> -->
                                    <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        Hospitality Short Courses</span>
                                </h1>
                            </div>
                            <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                                <section v-for="tdata in hsc" :key="tdata.id">
                                    <table class="w-full">
                                        <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                            {{tdata.name}}

                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </caption>

                                        <tbody class="divide-y divide-gray-200">
                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.modules}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.duration}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.grade}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.body}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </section>

                            </div>

                            <!-- lg+ -->
                            <div class="hidden lg:block">
                                <table class="w-full h-px table-fixed">

                                    <thead>
                                        <tr>
                                            <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                                <span>Course Name</span>
                                            </th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                            <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                        </tr>
                                    </thead>
                                    <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                        <tr v-for="tdata in hsc" :key="tdata.id">
                                            <td class="py-5 pr-6 w-6/12">
                                                <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                                <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {{tdata.mode}}
                                                    </span>
                                                </div>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                            </td>

                                            <td class="py-5 pl-6">
                                                <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                            </td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <br>
                        <!-- Hospitality Long Courses -->
                        <div>
                            <div class="mb-10">
                                <h1>
                                    <!-- <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span> -->
                                    <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        Hospitality Long Courses</span>
                                </h1>
                            </div>
                            <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                                <section v-for="tdata in hlc" :key="tdata.id">
                                    <table class="w-full">
                                        <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                            {{tdata.name}}

                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </caption>

                                        <tbody class="divide-y divide-gray-200">
                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.modules}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.duration}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.grade}}
                                                </td>
                                            </tr>

                                            <tr class="border-t border-gray-200">
                                                <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                                <td class="py-5 pr-4">
                                                    {{tdata.body}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </section>

                            </div>

                            <!-- lg+ -->
                            <div class="hidden lg:block">
                                <table class="w-full h-px table-fixed">

                                    <thead>
                                        <tr>
                                            <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                                <span>Course Name</span>
                                            </th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                            <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                            <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                        </tr>
                                    </thead>
                                    <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                        <tr v-for="tdata in hlc" :key="tdata.id">
                                            <td class="py-5 pr-6 w-6/12">
                                                <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                                <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {{tdata.mode}}
                                                    </span>
                                                </div>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                            </td>

                                            <td class="py-5 px-6">
                                                <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                            </td>

                                            <td class="py-5 pl-6">
                                                <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                            </td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- School Of Journalism -->
                    <div v-if="school.id == 2">
                        <div class="mb-10">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Courses in School of Journalism</span>
                            </h1>
                        </div>
                        <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                            <section v-for="tdata in jc" :key="tdata.id">
                                <table class="w-full">
                                    <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                        {{tdata.name}}

                                        <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {{tdata.mode}}
                                            </span>
                                        </div>
                                    </caption>

                                    <tbody class="divide-y divide-gray-200">
                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.modules}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.duration}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.grade}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.body}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </section>

                        </div>

                        <!-- lg+ -->
                        <div class="hidden lg:block">
                            <table class="w-full h-px table-fixed">

                                <thead>
                                    <tr>
                                        <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                            <span>Course Name</span>
                                        </th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                        <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                    </tr>
                                </thead>
                                <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                    <tr v-for="tdata in jc" :key="tdata.id">
                                        <td class="py-5 pr-6 w-6/12">
                                            <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                        </td>

                                        <td class="py-5 pl-6">
                                            <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>

                    <!-- School Of Engineering -->
                    <div v-if="school.id == 3">
                        <div class="mb-10">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Courses in School of Languages</span>
                            </h1>
                        </div>
                        <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                            <section v-for="tdata in ec" :key="tdata.id">
                                <table class="w-full">
                                    <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                        {{tdata.name}}

                                        <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {{tdata.mode}}
                                            </span>
                                        </div>
                                    </caption>

                                    <tbody class="divide-y divide-gray-200">
                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.modules}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.duration}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.grade}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.body}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </section>

                        </div>

                        <!-- lg+ -->
                        <div class="hidden lg:block">
                            <table class="w-full h-px table-fixed">

                                <thead>
                                    <tr>
                                        <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                            <span>Course Name</span>
                                        </th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                        <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                    </tr>
                                </thead>
                                <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                    <tr v-for="tdata in ec" :key="tdata.id">
                                        <td class="py-5 pr-6 w-6/12">
                                            <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                        </td>

                                        <td class="py-5 pl-6">
                                            <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>

                    <!-- School Of Computer Studies -->
                    <div v-if="school.id == 4">
                        <div class="mb-10">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Courses in School of Languages</span>
                            </h1>
                        </div>
                        <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                            <section v-for="tdata in cc" :key="tdata.id">
                                <table class="w-full">
                                    <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                        {{tdata.name}}

                                        <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {{tdata.mode}}
                                            </span>
                                        </div>
                                    </caption>

                                    <tbody class="divide-y divide-gray-200">
                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.modules}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.duration}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.grade}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.body}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </section>

                        </div>

                        <!-- lg+ -->
                        <div class="hidden lg:block">
                            <table class="w-full h-px table-fixed">

                                <thead>
                                    <tr>
                                        <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                            <span>Course Name</span>
                                        </th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                        <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                    </tr>
                                </thead>
                                <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                    <tr v-for="tdata in cc" :key="tdata.id">
                                        <td class="py-5 pr-6 w-6/12">
                                            <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                        </td>

                                        <td class="py-5 pl-6">
                                            <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>

                    <!-- School Of Languages -->

                    <div v-if="school.id == 5">
                        <div class="mb-10">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Courses in School of Languages</span>
                            </h1>
                        </div>
                        <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                            <section v-for="tdata in lc" :key="tdata.id">
                                <table class="w-full">
                                    <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                        {{tdata.name}}

                                        <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {{tdata.mode}}
                                            </span>
                                        </div>
                                    </caption>

                                    <tbody class="divide-y divide-gray-200">
                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.modules}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.duration}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.grade}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.body}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </section>

                        </div>

                        <!-- lg+ -->
                        <div class="hidden lg:block">
                            <table class="w-full h-px table-fixed">

                                <thead>
                                    <tr>
                                        <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                            <span>Course Name</span>
                                        </th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                        <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                    </tr>
                                </thead>
                                <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                    <tr v-for="tdata in lc" :key="tdata.id">
                                        <td class="py-5 pr-6 w-6/12">
                                            <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                        </td>

                                        <td class="py-5 pl-6">
                                            <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>

                    <!-- School Of Business -->
                    <div v-if="school.id == 7">
                        <div class="mb-10">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Courses in School of Business</span>
                            </h1>
                        </div>
                        <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                            <section v-for="tdata in bsnc" :key="tdata.id">
                                <table class="w-full">
                                    <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                        {{tdata.name}}

                                        <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {{tdata.mode}}
                                            </span>
                                        </div>
                                    </caption>

                                    <tbody class="divide-y divide-gray-200">
                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.modules}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.duration}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.grade}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.body}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </section>

                        </div>

                        <!-- lg+ -->
                        <div class="hidden lg:block">
                            <table class="w-full h-px table-fixed">

                                <thead>
                                    <tr>
                                        <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                            <span>Course Name</span>
                                        </th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                        <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                    </tr>
                                </thead>
                                <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                    <tr v-for="tdata in bsnc" :key="tdata.id">
                                        <td class="py-5 pr-6 w-6/12">
                                            <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                        </td>

                                        <td class="py-5 pl-6">
                                            <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>
                    <!-- School of Beauty-->
                    <div v-if="school.id == 10">
                        <div class="mb-10">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">Academics</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    Courses in School of Beauty</span>
                            </h1>
                        </div>
                        <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                            <section v-for="tdata in bc" :key="tdata.id">
                                <table class="w-full">
                                    <caption class="bg-gray-50 border-t border-gray-200 py-3 px-4 font-medium text-gray-900 text-left">
                                        {{tdata.name}}

                                        <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {{tdata.mode}}
                                            </span>
                                        </div>
                                    </caption>

                                    <tbody class="divide-y divide-gray-200">
                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Modules</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.modules}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Duration</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.duration}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Grades Required</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.grade}}
                                            </td>
                                        </tr>

                                        <tr class="border-t border-gray-200">
                                            <th class="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">Exam Body</th>
                                            <td class="py-5 pr-4">
                                                {{tdata.body}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </section>

                        </div>

                        <!-- lg+ -->
                        <div class="hidden lg:block">
                            <table class="w-full h-px table-fixed">

                                <thead>
                                    <tr>
                                        <th class="w-4/12 pb-4 pr-6 text-lg font-medium text-gray-900 text-left" scope="col">
                                            <span>Course Name</span>
                                        </th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Modules</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Duration</th>

                                        <th class="w-2/12 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Grades Required</th>

                                        <th class="w-2/12 pb-4 pl-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">Exam Body</th>
                                    </tr>
                                </thead>
                                <tbody class="border-t border-gray-200 divide-y divide-gray-200">

                                    <tr v-for="tdata in bc" :key="tdata.id">
                                        <td class="py-5 pr-6  w-6/12">
                                            <div class="text-sm text-gray-900">{{tdata.name}}</div>
                                            <div class="text-sm text-gray-500 mt-1">Mode: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {{tdata.mode}}
                                                </span>
                                            </div>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.modules}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.duration}}</span>
                                        </td>

                                        <td class="py-5 px-6">
                                            <span class="block text-sm text-gray-700">{{tdata.grade}}</span>
                                        </td>

                                        <td class="py-5 pl-6">
                                            <span class="block text-sm text-gray-700">{{tdata.body}}</span>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
// import gql from "graphql-tag";
// import sideNav from './sideNav.vue'
// import Header from './header.vue'
import Header from '../components/header.vue'
import HeaderImage from './headerImage.vue'
export default {
    data() {
        return {
            fixed: false,
            academic: [],
            school: [],
            tsc: [],
            tlc: [],
            hsc: [],
            hlc: [],
            jc: [],
            ec: [],
            cc: [],
            bsnc: [],
            bc: [],
            lc: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        // sideNav,
        Header,
        HeaderImage
    },
    mounted() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/schools/${this.$route.params.id}`).then(response => {
            this.school = response.data
        });

        this.axios.get(`https://cms.mahanaim.ac.ke/tourism-short-courses`).then(response => {
            this.tsc = response.data
        });
        this.axios.get(`https://cms.mahanaim.ac.ke/tourism-long-courses`).then(response => {
            this.tlc = response.data
        });
        this.axios.get(`https://cms.mahanaim.ac.ke/hospitality-short-courses`).then(response => {
            this.hsc = response.data
        });
        this.axios.get(`https://cms.mahanaim.ac.ke/hospitality-long-courses`).then(response => {
            this.hlc = response.data
        });
        this.axios.get(`https://cms.mahanaim.ac.ke/journalism-courses`).then(response => {
            this.jc = response.data
        });
        this.axios.get(`https://cms.mahanaim.ac.ke/engineering-courses`).then(response => {
            this.ec = response.data
        });
        this.axios.get(`https://cms.mahanaim.ac.ke/computer-courses`).then(response => {
            this.cc = response.data
        });
        this.axios.get(`https://cms.mahanaim.ac.ke/business-courses`).then(response => {
            this.bsnc = response.data
        });
        this.axios.get(`https://cms.mahanaim.ac.ke/beauty-courses`).then(response => {
            this.bc = response.data
        });
        this.axios.get(`https://cms.mahanaim.ac.ke/languages-courses`).then(response => {
            this.lc = response.data
        });

    }
}
</script>

<style scoped>
</style>
