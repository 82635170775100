<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <!-- Header -->
    <Header/>
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <div class="absolute inset-0">
                <img class="w-full h-full object-cover z-0" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100" alt="">
                <div class="absolute inset-0 bg-blue-800 opacity-80 rounded-md" aria-hidden="true"></div>
            </div>
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">{{blog.title}}</h1>
                <!-- <p class="mt-6 text-xl text-blue-100 max-w-3xl">pp amet hendrerit dolor, quisque lorem pharetra.
                    Pellentesque lacus nisi urna, arcu sociis eu. Orci vel lectus nisl eget eget ut consectetur. Sit justo
                    viverra non adipisicing elit distinctio.</p> -->
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">

        <!---Content--->
        <div class="relative max-w-7xl mx-auto mt-5">

            <div class="relative py-16 bg-white overflow-hidden">
                <div class="relative px-4 sm:px-6 lg:px-8">
                    <div class="text-lg max-w-prose mx-auto">
                        <h1>
                            <span class="block text-base text-center text-blue-600 font-semibold tracking-wide uppercase">{{blog.title}}</span>
                            <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{{blog.subtitle}}</span>
                        </h1>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par1}}</p><br>
                        <figure>
                            <img class="w-full rounded-lg" :src="api_url + blog.image.url" alt="" width="1310" height="873">
                            <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                        </figure>

                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par2}}</p>

                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par3}}</p>

                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par4}}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par5}}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par6}}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par7}}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par8}}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par9}}</p>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{blog.content.par10}}</p>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
// import Header from './header.vue'
import Header from '../components/header.vue'

export default {
    data() {
        return {
            fixed: false,
            blog: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components:{
     Header,
    },
    mounted() {

        window.scrollTo(0, 0);
        this.axios.get(`https://cms.mahanaim.ac.ke/blogs/${this.$route.params.id}`).then(response => {
            this.blog = response.data
        });
    }
}
</script>

<style>

</style>
