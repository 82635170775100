<template>
  <scroll-fixed-header
    :fixed.sync="fixed"
    :threshold="120"
  >
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto px-8 lg:divide-y lg:divide-gray-200 lg:px-8">
        <div class="relative flex justify-between">
          <div
            v-if="fixed == false"
            class="relative z-0 flex-1 py-5 flex items-center justify-center sm:inset-0"
          >
            <div class="w-full sm:max-w-xs">
              <Logo />
            </div>
          </div>
          <div class="relative z-10 flex items-center lg:hidden">
            <!-- Mobile menu button -->
            <button
              @click="toggle = !toggle"
              type="button"
              class="rounded-md p-2 inline-flex items-center justify-center text-mahanaim-blue-500 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span class="sr-only">Open menu</span>

              <svg
                class="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                class="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
          </div>
        </div>
        <nav
          class="hidden lg:py-3 lg:flex lg:space-x-8 lg:justify-center"
          aria-label="Global"
        >
          <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-900 hover:bg-gray-50 hover:text-gray-900" -->
          <router-link
            :to="{name: 'index'}"
            :class="$route.name == 'index' 
                ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium'"
            class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium"
            aria-current="page"
          >
            Home
          </router-link>

          <router-link
            :to="{name: 'greetings', params: { id: '1', slug: 'greetings' }}"
            :class="$route.name == 'greetings' 
                ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium'"
            class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium"
          >
            About Us
          </router-link>

          <router-link
            :to="{name: 'feeStructure', params: { id: '2', slug: 'fee-structure' }}"
            :class="['feeStructure', 'feeSummary'].includes($route.name)
                ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium'"
            class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium"
          >
            Admissions
          </router-link>

          <router-link
            :to="{name: 'schools', params: { id: '1', slug: 'schools' }}"
            :class="['schools', 'courses', 'descr'].includes($route.name)
                ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium'"
            class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium"
          >
            Academics
          </router-link>

          <router-link
            :to="{name: 'blog', params: { id: '1', slug: 'blog' }}"
            :class="['blog', 'blog-detail'].includes($route.name)
                ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium'"
            class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium"
          >
            News
          </router-link>

          <router-link
            :to="{name: 'contact'}"
            :class="$route.name == 'contact' 
                ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium'"
            class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium"
          >
            Contact Us
          </router-link>

          <router-link
            :to="{name: 'career'}"
            :class="['career', 'ViewCareer'].includes($route.name)
                ? 'bg-mahanaim-blue-500 text-white rounded-md py-2 px-3 inline-flex items-center text-sm font-medium': 'text-blue-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium'"
            class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium"
          >
            Career
          </router-link>
          <div class="md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
            <a
              v-bind:href="links.login"
              target="about_blank"
              class="whitespace-no-wrap text-base leading-6 font-semibold text-gray-600 hover:text-gray-900 transition ease-in-out duration-150"
            >
              Student Login
            </a>
            <span class="inline-flex rounded-md shadow-sm">
              <a
                v-bind:href="links.register"
                target="about_blank"
                class="whitespace-no-wrap inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-mahanaim-blue-500 hover:bg-mahanaim-blue-600 focus:outline-none transition ease-in-out duration-150"
              >
                Register
              </a>
            </span>
          </div>
        </nav>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <nav
        v-if="toggle == true"
        class="lg:hidden border-t"
        aria-label="Global"
        id="mobile-menu"
      >
        <div
          @click="toggle ==false"
          class="px-8 pt-8 pb-4 space-y-1"
        >
          <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-900 hover:bg-gray-50 hover:text-gray-900" -->
          <router-link
            :to="{name: 'index'}"
            :class="$route.name == 'index'
            ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium'"
            class="block rounded-md py-2 px-3 text-base font-medium"
            aria-current="page"
          >Home</router-link>

          <router-link
            :to="{name: 'greetings', params: { id: '1', slug: 'greetings' }}"
             :class="$route.name == 'greetings'
            ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium'"
            class="block rounded-md py-2 px-3 text-base font-medium"
          >About Us</router-link>

          <router-link
            :to="{name: 'feeStructure', params: { id: '2', slug: 'fee-structure' }}"
             :class="['feeStructure', 'feeSummary'].includes($route.name)
            ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium'"
            class="block rounded-md py-2 px-3 text-base font-medium"
          >Admissions</router-link>

          <router-link
            :to="{name: 'schools', params: { id: '1', slug: 'schools' }}"
             :class="['schools', 'courses', 'descr'].includes($route.name)
            ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium'"
            class="block rounded-md py-2 px-3 text-base font-medium"
          >Academics</router-link>

          <router-link
            :to="{name: 'blog', params: { id: '1', slug: 'blog' }}"
             :class="['blog', 'blog-detail'].includes($route.name)
            ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium'"
            class="block rounded-md py-2 px-3 text-base font-medium"
          >News</router-link>

          <router-link
            :to="{name: 'contact'}"
            :class="$route.name == 'contact'
            ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium'"
            class="block rounded-md py-2 px-3 text-base font-medium"
          >Contact Us</router-link>
          
          <router-link
            :to="{name: 'career'}"
            :class="['career', 'ViewCareer'].includes($route.name)
            ? 'bg-mahanaim-blue-500 text-white block rounded-md py-2 px-3 text-base font-medium' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 block rounded-md py-2 px-3 text-base font-medium'"
            class="block rounded-md py-2 px-3 text-base font-medium"
          >Career</router-link>
        </div>
        <div class="px-8 border-t pt-6 pb-8">
          <a
            v-bind:href="links.register"
            target="about_blank"
            class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-mahanaim-blue-500 hover:bg-mahanaim-blue-600"
          >
            Register
          </a>
          <p class="mt-6 text-center text-base font-medium text-gray-500">
            <!-- space -->
            <a
              v-bind:href="links.login"
              target="about_blank"
              class="text-mahanaim-blue-500 hover:text-mahanaim-blue-600"
            >
              Student Login
            </a>
          </p>
        </div>
      </nav>
    </header>
  </scroll-fixed-header>
</template>

<script>
import Logo from "../components/logo.vue";
export default {
  data() {
    return {
      fixed: false,
      toggle: false,
      links: [],
    };
  },
  components: {
    Logo,
  },
  mounted() {
    this.axios.get(`https://cms.mahanaim.ac.ke/auth-links`).then((response) => {
      this.links = response.data;
    });
  },
  methods: {
    myFunction() {
      var x = document.getElementById("myTopnav");
      if (x.className === "topnav") {
        x.className += " responsive";
      } else {
        x.className = "topnav";
      }
    },
  },
};
</script>

<style scoped>
.topnav {
  transition-delay: 2s;
}
</style>
