<template>
<div class="relative bg-white border-t-6 border-blue-700">
    <!-- Header -->
    <Header />
    <div class="max-w-screen-xl mx-auto px-8 mt-5">
        <div class="relative bg-blue-800 rounded-md">
            <HeaderImage />
            <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-24 sm:px-6 lg:px-16">
                <h1 class="text-4xl font-bold tracking-wide text-white sm:text-5xl ">{{admission.title}}</h1>
            </div>
        </div>
    </div>

    <div class="max-w-screen-xl mx-auto px-8">
        <!---Content--->
        <div class="relative bg-white max-w-7xl mx-auto grid grid-cols-3 gap-2 lg:gap-8 lg:items-start">
            <div class="col-span-full md:col-span-2 pb-16 md:pt-16 order-last md:order-first">
                <!---Intoduction--->
                <div>

                    <!-- apply -->

                    <div v-if="admission.id == '1'">
                        <div class="text-lg max-w-prose">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{admission.heading}}</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    {{admission.subheading}}</span>
                            </h1>
                        </div>
                    </div>

                    <!-- Fee Structure -->
                    <div v-if="admission.id == '2'" class="">
                        <div class="text-lg max-w-prose">
                            <div class="text-lg max-w-prose">
                                <h1>
                                    <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{admission.heading}}</span>
                                    <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        {{admission.subheading}}</span>
                                </h1>
                            </div>
                        </div>
                        <div class="bg-white border-2 border-gray-200 overflow-hidden sm:rounded-md mt-9">
                            <ul role="list" class="divide-y divide-gray-200">
                                <li v-for="fee in fees" :key="fee.id">
                                    <a class="block hover:bg-gray-50">
                                        <div class="px-4 py-4 sm:px-6">
                                            <div class="block md:flex items-center justify-between">
                                                <p class="text-md font-medium text-gray-900 truncate">
                                                    {{ fee.school }}
                                                </p>
                                                <div class="mt-3 md:mt-0 md:ml-2 flex-shrink-0 flex">
                                                    <router-link :to="{name:'feeSummary', params:{id: fee.id}}" class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mahanaim-blue-500">
                                                        Summary
                                                    </router-link>
                                                    <a v-bind:href="api_url + fee.doc.url" download target="_blank" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-mahanaim-blue-500 hover:bg-mahanaim-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mahanaim-blue-500">
                                                        Download
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <!-- scholarship -->

                    <div v-if="admission.id == '3'">
                        <div class="text-lg max-w-prose">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{admission.heading}}</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    {{admission.subheading}}</span>
                            </h1>
                        </div>
                        <!-- <h1>
                            <span class="mt-8 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                {{admission.scholarship.title}}</span>
                        </h1> -->
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ admission.scholarship.intro }}</p>

                        <h2>{{admission.scholarship.head1}}</h2>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ admission.scholarship.par1 }}</p>
                        <h2>{{admission.scholarship.head2}}</h2>
                        <p class="">{{ admission.scholarship.par2}}</p>
                        <h2>{{admission.scholarship.head3}}</h2>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ admission.scholarship.par3 }}</p>
                        <h2>{{admission.scholarship.head4}}</h2>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ admission.scholarship.par4 }}</p>
                        <h2>{{admission.scholarship.head5}}</h2>
                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ admission.scholarship.par5 }}</p>
                        <blockquote>
                            <p>{{admission.scholarship.head6}}</p>
                        </blockquote>
                        <!-- <p class="mt-8 text-xl text-gray-500 leading-8">{{ admission.scholarship.par6 }}</p> -->
                    </div>

                    <!-- Press -->

                    <div v-if="admission.id == '4'">
                        <div class="text-lg max-w-prose">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{admission.heading}}</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    {{admission.subheading}}</span>
                            </h1>
                        </div>
                    </div>

                    <!-- Partners -->

                    <div v-if="admission.id == '5'">
                        <div class="text-lg max-w-prose">
                            <h1>
                                <span class="block text-base text-blue-600 font-semibold tracking-wide uppercase">{{admission.heading}}</span>
                                <span class="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                    {{admission.subheading}}</span>
                            </h1>
                        </div>

                        <p class="mt-8 text-xl text-gray-500 leading-8">{{ admission.scholarship.intro }}</p>

                        <div>
                            <figure>
                                <img v-if="admission.image.url" class="w-full rounded-lg" :src="api_url + admission.image.url" alt="" width="1310" height="873">
                                <!-- <figcaption>{{about.chancellor.name}}</figcaption> -->
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <!-- sidenav -->
            <sideNav />
        </div>
    </div>
</div>
</template>

<script>
// import gql from "graphql-tag";
import sideNav from './sideNav.vue'
// import Header from './header.vue'
// import Header from '../components/header.vue'
import HeaderImage from './headerImage.vue'
import Header from '../components/header.vue'
export default {
    data() {
        return {
            fixed: false,
            admission: [],
            fees: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        }
    },
    components: {
        sideNav,
        Header,
        HeaderImage
    },
    mounted() {
        window.scrollTo(0, 0);

        this.axios.get(`https://cms.mahanaim.ac.ke/admissions/${this.$route.params.id}`).then(response => {
            this.admission = response.data
        });

        this.axios.get(`https://cms.mahanaim.ac.ke/fees`).then(response => {
            this.fees = response.data
        });

    }
}
</script>

<style>

</style>
